import React from "react";
import { Location } from "@reach/router";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import ImgWithOverlay from "../../components/img-with-overlay";

function DiscoveryProject(props) {
  return (
    <Layout>
      <Location>
        {({ location }) => (
          <SEO
            title="Discovery Project"
            pathname={location.pathname}
            keywords={[
              "Custom Software Development",
              "gortek",
              "AWS Cloud Services",
              "API Development",
              "AWS Lambda microservices",
              "Data Processing",
              "Data Migration",
              "ETL",
              "Python",
              "Apache Airflow"
            ]}
          />
        )}
      </Location>

      <section>
        <ImgWithOverlay
          filename="adventure-backpacker-daylight-2609459.jpg"
          height="260px"
          minHeight="260px"
          backgroundColor="#1464f688"
          backgroundPosition="center"
        >
          <div className="flex h-full flex-col">
            <h1 className="mt-auto text-white text-4xl w-full text-center">
              Lets Have A Discovery Project
            </h1>
            <h2 className=" text-white text-xl w-full text-center pb-4">
              A little investment now saves a fortune later
            </h2>
          </div>
        </ImgWithOverlay>
      </section>

      <section className="mt-16 mx-auto sm:w-2/3">
        <h2 className="text-primary text-3xl">Discovery Project</h2>
        <h3 className="mt-6 text-lg">What to expect</h3>

        <p className="mt-2">
          The central idea for a discovery project is massive amounts of
          communication.
        </p>

        <p className="mt-2">
          Your team at Gortek will need timely access to the members of your
          staff for scheduled meetings, and ad-hoc questions as we're processing
          everything.
        </p>

        <h3 className="mt-6 text-lg">
          What is the format?
        </h3>

        <p className="mt-2">
          Our discovery projects are loosely based on the concept of{" "}
          <a
            className="text-primary underline"
            href="https://www.gv.com/sprint/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Design Sprints, pioneered by Google
          </a>{" "}
          for making rapid progress in organizational decision making.
        </p>
        <p className="mt-2">Of course, we've modified it quite a bit.</p>

        <h3 className="mt-6 text-lg">
          Is it just one week long?
        </h3>

        <p className="mt-2">
          We start with a single sprint to map out everything at the highest
          level. Whenever we uncover a rabbit-hole of complexity, we mark it as
          such and back out. Each area like this will potentially get its own
          sprint to plumb the depths and chart its extent.
        </p>

        <p className="mt-2">
          So, depending on how complex your project and organization is, it
          could be a single week, or multiple sprints to discover the true
          nature and scope of your project.
        </p>

        <p className="mt-2">
          The beauty of this approach is it can be dialed back or pushed further
          at will. Each iteration is a fully complete, self contained "module"
          and ends with documented learning that will be a valuable resource for
          your business.
        </p>

        <h3 className="mt-6 text-lg">
          Is this overkill?
        </h3>

        <p className="mt-2">
          Absolutely not. Nearly every software project that goes over budget or
          schedule did so because of improper planning. The management and
          technical teams simply didn't have all the information necessary to
          predict the future. They didn't fully understand the scope and
          complexity of the software they wanted to build.
        </p>

        <h3 className="mt-6 text-lg">
          Why do so many other projects fail?
        </h3>

        <p className="mt-2">
          In many cases, it's because business leaders want an ISV or software
          development firm to give a solid quote before the actual project ever
          begins.
        </p>

        <p className="mt-2">
          These companies can only afford to devote so much time and resources
          digging into the project while working for free, hoping to win the big
          contract that makes it worthwhile.
        </p>

        <p className="mt-2">
          The result is a{" "}
          <a
            href="https://en.wikipedia.org/wiki/Scientific_wild-ass_guess"
            target="_blank"
            className="text-primary underline"
          >
            SWAG
          </a>{" "}
          at best, most often a <em>very wrong</em> estimate, and at worst
          &mdash; an intentionally under-estimated quote to win the work as the
          cheapest solution only to continually need more money and time once
          work has begun.
        </p>

        <h3 className="mt-6 text-lg">
            OK, I'm convinced. How do we start a discovery project?
        </h3>

        <p className="mt-2">
          Just send us a note to schedule a time to begin. Then we'll discover
          your project plan, time frame, and costs together.
        </p>

        <div className="ml-4 mt-6">
          <h3 className="mt-6 text-lg">
            Say <span className="text-primary">hello@gortek.com</span>.
          </h3>
          <p className="mt-2 ml-4">
            Copy & paste this into your email, along with your brief answers.
          </p>
          <ul
            className="ml-4 sm:ml-6 md:ml-8 lg:ml-10"
            style={{
              listStyleType: "disc",
              listStylePosition: "inside"
            }}
          >
            <li>Tell us about you and your business...</li>
            <li>Tell us about the project...</li>
            <li>How far have you progressed with this project?</li>
            <li>
              Have you already tried developing a solution for this before?
            </li>
            <li>What is your time frame?</li>
            <li>What is your budget?</li>
            <li>
              Do you have IT/software development/technical leadership staff
              in-house?
            </li>
          </ul>
        </div>
      </section>
      <div className="mb-16" />
    </Layout>
  );
}

export default DiscoveryProject;
